import * as Yup from "yup";

const patientSchema = Yup.object().shape({
  firstname: Yup.string().required("Name is required").min(3),
  middlename: Yup.string(),
  lastname: Yup.string(),
  address: Yup.string().required("Address is required").min(10),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid phone number")
    .min(10, "Phone number must be at least 10 digits")
    .required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  image: Yup.string().required("Image is required"),
  gender: Yup.string().required("Please select gender"),
  bloodGroup: Yup.string().required("Please select blood group"),
  // maritalStatus: Yup.string().required("Please select marital status"),
  occupation: Yup.string().required("Please select occupation"),
  dateOfBirth: Yup.date().required("Please select date of birth"),
  referredBy: Yup.string(),
  // religion: Yup.string().required("Religion is required"),
  // education: Yup.string().required("Education is required"),
  // workStation: Yup.string().required("Work Station is required"),
});

export default patientSchema;
