import React, { useState } from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import DropdownSelect from "../../components/core/Dropdown";
import { bloodGroup, occupation, gender } from "../../Data/Options";
import Button from "../../components/core/Button";
import Dpicker from "../../components/core/Dpicker";
import ProfileDocument from "../../components/components/ProfileDocument";
import { useFormik } from "formik";
import patientSchema from "../../validation/patients";
import { postApi } from "../../hooks/Api";
import { toast } from "react-toastify";
import FormContainer from "../../components/components/FormContainer";
import { useNavigate } from "react-router-dom";

function AddPatient() {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      phone: "",
      address: "",
      image: "",
      gender: "",
      bloodGroup: "",
      occupation: "",
      dateOfBirth: "",
      referredBy: "",
      adhaarNumber: "",
      secondaryNumber: "",
      hearYouKnow: "",
      middlename: "",
      lastname: "",
    },
    validationSchema: patientSchema,
    onSubmit: async (values) => {
      setloading(true);
      const formData = new FormData();
      formData.append("firstname", values.firstname);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("address", values.address);
      formData.append("image", values.image);
      formData.append("adhaarNumber", values.adhaarNumber);
      formData.append("secondaryNumber", values.secondaryNumber);
      formData.append("hearYouKnow", values.hearYouKnow);
      formData.append("middlename", values.middlename);
      formData.append("lastname", values.lastname);
      formData.append(
        "gender",
        gender.filter((item) => item.id === parseInt(values.gender))[0].name
      );
      formData.append(
        "bloodGroup",
        bloodGroup.filter((item) => item.id === parseInt(values.bloodGroup))[0]
          .name
      );
      formData.append(
        "occupation",
        occupation.filter((item) => item.id === parseInt(values.occupation))[0]
          .name
      );
      formData.append("dateOfBirth", values.dateOfBirth);
      formData.append("referredBy", values.referredBy);

      const response = await postApi("/patient", formData);
      if (response?.success) {
        toast.success(response?.message);
        navigate("/success");
      } else {
        toast.error(response?.message);
      }
      setloading(false);
    },
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-100">
      <div className="w-10/12 max-w-7xl space-y-8 bg-white rounded shadow-md mx-auto my-10 py-10">
        <Container>
          <p className="text-xl font-poppins font-bold my-5 ">
            Patient Registration Form
          </p>
          <ProfileDocument
            touched={touched.image}
            errors={errors.image}
            label="Upload Image"
            Url={values.image}
            setUrl={(e) => {
              setFieldValue("image", e);
            }}
          />

          <FormContainer>
            <TextInput
              label="First Name"
              type="text"
              placeholder="Enter first name"
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.firstname}
              errors={errors.firstname}
              name="firstname"
              id="firstname"
              maxLength={50}
              Icon={null}
            />
            <TextInput
              label="Middle Name"
              type="text"
              placeholder="Enter middle name"
              value={values.middlename}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.middlename}
              errors={errors.middlename}
              name="middlename"
              id="middlename"
              maxLength={50}
              Icon={null}
            />
            <TextInput
              label="Last Name"
              type="text"
              placeholder="Enter last name"
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.lastname}
              errors={errors.lastname}
              name="lastname"
              id="lastname"
              maxLength={50}
              Icon={null}
            />

            <Dpicker
              label="Date of Birth"
              placeholder="Choose Date of Birth"
              value={values.dateOfBirth}
              onChange={(e) => {
                setFieldValue("dateOfBirth", e);
              }}
              onBlur={handleBlur}
              touched={touched.dateOfBirth}
              errors={errors.dateOfBirth}
              name="dateOfBirth"
              id="dateOfBirth"
              maxLength={10}
              Icon={null}
            />

            <DropdownSelect
              defaultValue="Select gender"
              value={values.gender}
              label="Gender"
              options={gender}
              onChange={(e) => {
                setFieldValue("gender", e.target.value);
              }}
              onBlur={handleBlur}
              touched={touched.gender}
              errors={errors.gender}
              name="gender"
              id="gender"
            />

            <TextInput
              label="Email"
              type="email"
              placeholder="Enter Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.email}
              errors={errors.email}
              name="email"
              id="email"
              maxLength={50}
              Icon={null}
            />

            <TextInput
              label="Phone"
              type="text"
              placeholder="Enter Phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.phone}
              errors={errors.phone}
              name="phone"
              id="phone"
              maxLength={10}
              Icon={null}
            />

            <TextInput
              label="Secondary Number"
              type="text"
              placeholder="Enter Secondary Number"
              value={values.secondaryNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.secondaryNumber}
              errors={errors.secondaryNumber}
              name="secondaryNumber"
              id="secondaryNumber"
              maxLength={10}
              Icon={null}
            />

            <TextInput
              label="Address"
              type="text"
              placeholder="Enter Address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.address}
              errors={errors.address}
              name="address"
              id="address"
              maxLength={50}
              Icon={null}
            />

            <TextInput
              label="Adhaar Number"
              type="text"
              placeholder="Enter Adhaar Number"
              value={values.adhaarNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.adhaarNumber}
              errors={errors.adhaarNumber}
              name="adhaarNumber"
              id="adhaarNumber"
              maxLength={12}
              Icon={null}
            />

            <DropdownSelect
              defaultValue="Select blood group"
              label="Blood Group"
              value={values.bloodGroup}
              options={bloodGroup}
              onChange={(e) => {
                setFieldValue("bloodGroup", e.target.value);
              }}
              onBlur={handleBlur}
              touched={touched.bloodGroup}
              errors={errors.bloodGroup}
              name="bloodGroup"
              id="bloodGroup"
            />

            <DropdownSelect
              defaultValue="Select occupation"
              label="Occupation"
              value={values.occupation}
              options={occupation}
              onChange={(e) => {
                setFieldValue("occupation", e.target.value);
              }}
              onBlur={handleBlur}
              touched={touched.occupation}
              errors={errors.occupation}
              name="occupation"
              id="occupation"
            />

            <TextInput
              label="Hear you Know"
              type="text"
              placeholder="Enter Hear you Know"
              value={values.hearYouKnow}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.hearYouKnow}
              errors={errors.hearYouKnow}
              name="hearYouKnow"
              id="hearYouKnow"
              maxLength={50}
              Icon={null}
            />

            <TextInput
              label="Referred By"
              type="text"
              placeholder="Enter Referred By"
              value={values.referredBy}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.referredBy}
              errors={errors.referredBy}
              name="referredBy"
              id="referredBy"
              maxLength={10}
              Icon={null}
            />
          </FormContainer>
          <Button
            variant="contained"
            title="Submit"
            style={{ marginBlock: 20 }}
            className="w-full md:w-2/12"
            onClick={handleSubmit}
            loading={loading}
          />
        </Container>
      </div>
    </div>
  );
}

export default AddPatient;
