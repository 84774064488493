import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function Menu({ menu, index, logout }) {
  const { label, icon: Icon, to: location, notification } = menu;
  const route = useLocation();
  return (
    <li key={index} className="list-none ">
      <NavLink
        to={location}
        className={`flex items-center justify-between text-gray-500 my-0.5 py-3 px-2 rounded-lg ${
          location === route.pathname ? "bg-primaryLight text-primary" : ""
        }`}
        onClick={() => {
          if (location === "/login") {
            logout();
          }
        }}
      >
        <div className="flex items-center space-x-3">
          {Icon}
          <p
            className={`font-medium text-sm ${
              location === route.pathname ? "text-primary" : "text-gray"
            }`}
          >
            {label}
          </p>
        </div>

        <div className="flex flex-row items-center">
          {
            // Show notification only if it is available
            notification && (
              <span
                className={`${
                  location === route.pathname ? "bg-primary" : "bg-gray-300"
                } text-white text-xs font-medium px-1.5 py-0.5 rounded-full`}
              >
                {notification}
              </span>
            )
          }

          <NavigateNextOutlinedIcon
            color={location === route.pathname ? "#5F61E6" : "gray"}
            className="w-4 h-4"
          />
        </div>
      </NavLink>
    </li>
  );
}

export default Menu;
