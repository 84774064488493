import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function Navbar() {
  const { siteData } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div className=" flex flex-row justify-between items-center py-3.5 px-2 md:px-6 container mx-auto">
      <div
        className="flex flex-row items-center space-x-3 hover:cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img
          src={siteData?.logo}
          alt=""
          className="w-10 h-10 object-contain rounded-full"
        />

        <h1 className="text-lg font-bold font-poppins">Homeohopes</h1>
      </div>

      <ul className="hidden lg:flex flex-row justify-center items-center space-x-4">
        <li>
          <NavLink
            className="font-semibold hover:cursor-pointer font-poppins"
            to="/"
          >
            Home
          </NavLink>
        </li>
        <li>
          <a
            className="font-semibold hover:cursor-pointer font-poppins"
            href="#about"
          >
            About Us
          </a>
        </li>
        <li>
          <a
            className="font-semibold hover:cursor-pointer font-poppins"
            href="#contact"
          >
            Contact Us
          </a>
        </li>
        <li>
          <a
            className="font-semibold hover:cursor-pointer font-poppins"
            href="#review"
          >
            Review
          </a>
        </li>
        <li>
          <a
            className="font-semibold hover:cursor-pointer font-poppins"
            href="#faq"
          >
            Faq
          </a>
        </li>
      </ul>
      <NavLink
        to="/book-appointment"
        className=" bg-primary animate-pulse py-3 px-6 rounded-full "
      >
        <p className="text-sm  font-semibold  text-white font-poppins ">
          Book Appointment
        </p>
      </NavLink>
    </div>
  );
}

export default Navbar;
