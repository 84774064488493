const gender = [
  { name: "Male", id: 1 },
  { name: "Female", id: 2 },
  { name: "Other", id: 3 },
];

const bloodGroup = [
  { name: "A+", id: 1 },
  { name: "A-", id: 2 },
  { name: "B+", id: 3 },
  { name: "B-", id: 4 },
  { name: "AB+", id: 5 },
  { name: "AB-", id: 6 },
  { name: "O+", id: 7 },
  { name: "O-", id: 8 },
  { name: "Not known", id: 9 },
];

const maritalStatus = [
  { name: "Single", id: 1 },
  { name: "Married", id: 2 },
  { name: "Divorced", id: 3 },
  { name: "Widowed", id: 4 },
  { name: "Separated", id: 5 },
  { name: "Other", id: 6 },
];

const occupation = [
  { name: "Student", id: 1 },
  { name: "Employed", id: 2 },
  { name: "Self-employed", id: 3 },
  { name: "Unemployed", id: 4 },
  { name: "Retired", id: 5 },
  { name: "Other", id: 6 },
];

const referredBy = [
  { name: "Doctor", id: 1 },
  { name: "Friend", id: 2 },
  { name: "Internet", id: 3 },
  { name: "Other", id: 4 },
];

const religions = [
  { name: "Hinduism", id: 1 },
  { name: "Christianity", id: 2 },
  { name: "Islam", id: 3 },
  { name: "Buddhism", id: 4 },
  { name: "Sikhism", id: 5 },
  { name: "Judaism", id: 6 },
  { name: "Jainism", id: 7 },
  // Add more religions as needed
];

const educations = [
  { name: "High School", id: 1 },
  { name: "Associate's Degree", id: 2 },
  { name: "Bachelor's Degree", id: 3 },
  { name: "Master's Degree", id: 4 },
  { name: "Doctorate", id: 5 },
  { name: "Professional Degree", id: 6 },
  // Add more education levels as needed
];

const diseases = [
  {
    name: "Present",
    id: 1,
  },
  {
    name: "Past",
    id: 2,
  },
];

const relations = [
  {
    name: "Parent",
    id: 1,
  },
  {
    name: "Child",
    id: 2,
  },
  {
    name: "Sibling",
    id: 3,
  },
  {
    name: "Spouse",
    id: 4,
  },
  {
    name: "Grandparent",
    id: 5,
  },
  {
    name: "Grandchild",
    id: 6,
  },
  {
    name: "Aunt",
    id: 7,
  },
  {
    name: "Uncle",
    id: 8,
  },
  {
    name: "Niece",
    id: 9,
  },
  {
    name: "Nephew",
    id: 10,
  },
  {
    name: "Cousin",
    id: 11,
  },
  {
    name: "Step-Parent",
    id: 12,
  },
  {
    name: "Step-Child",
    id: 13,
  },
  {
    name: "Step-Sibling",
    id: 14,
  },
  {
    name: "Half-Sibling",
    id: 15,
  },
  {
    name: "Guardian",
    id: 16,
  },
  {
    name: "Father",
    id: 17,
  },
  {
    name: "Mother",
    id: 18,
  }, // Add more relations as needed
];

const role = [
  {
    name: "Admin",
    id: 1,
  },
  {
    name: "Assistant",
    id: 0,
  },
];

const boolean = [
  {
    name: "yes",
    id: 1,
  },
  {
    name: "no",
    id: 2,
  },
];

const unit = [
  {
    name: "pounds",
    id: 1,
  },
  {
    name: "ml",
    id: 2,
  },
];

const times = [
  {
    id: 1,
    name: "Today",
  },
  {
    name: "Weekly",
    id: 2,
  },
  {
    name: "Monthly",
    id: 3,
  },
  {
    name: "Quarterly",
    id: 4,
  },
  {
    name: "Yearly",
    id: 5,
  },
];

const paymentMode = [
  {
    name: "Cash",
    id: 1,
  },
  {
    name: "Card",
    id: 2,
  },
  {
    name: "UPI",
    id: 3,
  },
  {
    name: "Netbanking",
    id: 4,
  },
];

export {
  gender,
  bloodGroup,
  maritalStatus,
  occupation,
  referredBy,
  role,
  boolean,
  unit,
  educations,
  religions,
  diseases,
  relations,
  times,
  paymentMode,
};
