import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/material";
import { postApi } from "../../hooks/Api";

function Hero() {
  const [Images, setImages] = useState([]);
  const getData = async () => {
    const response = await postApi("courasel/get");
    setImages(response?.data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Carousel
      // showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      stopOnHover={true}
      showStatus={false}
      swipeable={true}
      renderArrowPrev={(clickHandler, hasPrev) => {
        return (
          <Box
            sx={{
              position: Images.length > 0 ? "absolute" : "hidden",
              top: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bottom: 0,
              left: 0,
              padding: 3,
              zIndex: 10,
            }}
            onClick={clickHandler}
          >
            <ion-icon
              name="chevron-back-circle-outline"
              size="large"
            ></ion-icon>
          </Box>
        );
      }}
      renderArrowNext={(clickHandler, hasNext) => {
        return (
          <Box
            sx={{
              position: Images.length > 0 ? "absolute" : "hidden",
              top: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bottom: 0,
              right: 0,
              padding: 3,
            }}
            onClick={clickHandler}
          >
            <ion-icon
              name="chevron-forward-circle-outline"
              size="large"
            ></ion-icon>
          </Box>
        );
      }}
    >
      {Images &&
        Images.map((item, index) => (
          <div className="md:h-[75vh] h-72" key={index}>
            <img
              src={item.image}
              alt=""
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
        ))}
    </Carousel>
  );
}

export default Hero;
