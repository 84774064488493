import * as yup from "yup";

const diseaseSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  symptoms: yup.string().required("Symptoms are required"),
  causes: yup.string().required("Causes are required"),
});

export default diseaseSchema;
