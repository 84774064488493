import React from "react";
import Text from "./Text";

function Errortext(props) {
  return (
    <Text
      style={{ color: "red", fontSize: 12, fontWeight: "normal", marginTop: 4 }}
    >
      {props.error}
    </Text>
  );
}

export default Errortext;
