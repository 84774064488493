import React, { useContext, useEffect, useState } from "react";
import Button from "../components/core/Button";
import { load } from "@cashfreepayments/cashfree-js";
import { postApi } from "../hooks/Api";
import TextInput from "../components/core/TextInput";
import Link from "../components/core/Link";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Dpicker from "../components/core/Dpicker";
import Tpicker from "../components/core/Tpicker";
import Textarea from "../components/core/Textarea";
import { useFormik } from "formik";
import appointmentSchema from "../validation/appointmentSchema";
import { toast } from "react-toastify";
import formateDate from "../hooks/formateDate";
import { AuthContext } from "../context/AuthContext";
import { constant } from "../utils/constant";
import Loading from "../components/core/Loading";

const steps = [
  "Enter Registration Number",
  "Confirm Your Details",
  "Choose Appointment Date",
  "Choose Payment Method",
  "Success",
];

function BookAppointment() {
  const [registrationNumber, setRegistrationNumber] = useState("");
  const { siteData } = useContext(AuthContext);
  const [patient, setPatient] = useState({});
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cashfree, setCashfree] = useState(null); // State to store the initialized SDK

  const initializeSDK = async () => {
    try {
      const sdk = await load({ mode: "sandbox" });
      setCashfree(sdk);
    } catch (error) {
      console.error("Failed to initialize Cashfree SDK", error);
      toast.error("Failed to initialize payment system.");
    }
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      patientId: "",
      date: "",
      time: "",
      description: "",
    },
    validationSchema: appointmentSchema,
    onSubmit: async (values) => {
      setStep(3);
    },
  });

  const doPayment = async () => {
    if (!cashfree) {
      toast.error("Payment system is not initialized.");
      return;
    }

    try {
      const response = await postApi("/transaction", {
        amount: siteData?.appointmentfees,
        phone: patient?.phone,
        email: patient?.email,
        name: patient?.firstname,
        patientId: patient?.patientId,
      });

      if (!response?.data?.payment_session_id) {
        toast.error("Failed to create payment session.");
        return;
      }

      let checkoutOptions = {
        paymentSessionId: response?.data?.payment_session_id,
        redirectTarget: "_modal",
      };

      cashfree.checkout(checkoutOptions).then((result) => {
        if (result.error) {
          toast.error(result.error.message);
        }
        if (result.redirect) {
          console.log("Payment will be redirected");
        }
        if (result.paymentDetails) {
          bookAppointment(response?.data?.order_id);
        }
      });
    } catch (error) {
      console.error("Payment process failed", error);
      toast.error("Failed to process payment.");
    }
  };

  useEffect(() => {
    initializeSDK();
  }, []);

  const findPatient = async () => {
    setLoading(true);
    const response = await postApi(`patient/${registrationNumber}`);
    if (response.success) {
      setPatient(response.data.patient);
      setStep(1);
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  const bookAppointment = async (order_id) => {
    const response = await postApi("appointment", {
      ...values,
      orderId: order_id,
    });
    if (response.success) {
      setStep(4);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen ">
      <Loading isLoading={loading} />
      <div className="w-10/12 lg:w-6/12 shadow-md rounded-md py-20 px-5 lg:px-10">
        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className="flex justify-center items-center my-10">
          {step === 0 && (
            <div className="">
              <p className="font-poppins my-2">Enter Registration Number</p>
              <TextInput
                placeholder="Enter Registration Number"
                icon="search"
                onChange={(e) => setRegistrationNumber(e.target.value)}
                value={registrationNumber}
              />

              <Button title="Search" className="my-2" onClick={findPatient} />

              <Link
                to="/registration"
                title="Don't Have an Registration Number ? Register"
                className="my-4"
              />
            </div>
          )}

          {step === 1 && (
            <div>
              <div className="flex flex-row justify-between items-center space-x-4 bg-white px-8 py-4 rounded-lg">
                <img
                  src={patient.image}
                  alt="patient"
                  className="w-28 h-28 rounded-full border border-gray"
                />
                <div className="my-10">
                  <p className="font-poppins text-sm">
                    Name: {patient.firstname}
                  </p>
                  <p className="font-poppins text-sm">Email: {patient.email}</p>
                  <p className="font-poppins text-sm">Phone: {patient.phone}</p>
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="w-full md:w-9/12">
              <Dpicker
                label="Date"
                name="date"
                value={values.date}
                onChange={(value) => {
                  setFieldValue("date", value);
                }}
                minDate={new Date()}
                onBlur={handleBlur}
                errors={errors.date}
                touched={touched.date}
              />
              <Tpicker
                label="Time"
                name="time"
                value={values.time}
                onChange={(value) => {
                  setFieldValue("time", value);
                }}
                onBlur={handleBlur}
                errors={errors.time}
                touched={touched.time}
                disablePast={true}
                id="time"
              />

              <Textarea
                label="Description"
                name="description"
                placeholder="Enter Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.description}
                touched={touched.description}
                multiline={true}
              />
            </div>
          )}

          {step === 3 && (
            <div className="flex justify-center flex-col space-y-3 ">
              <p className="font-poppins text-lg">Your Details</p>

              <div className="flex flex-row justify-between items-center space-x-4 bg-white px-8 rounded-lg">
                <img
                  src={patient.image}
                  alt="patient"
                  className="w-28 h-28 rounded-full border border-gray"
                />
                <div>
                  <p className="font-poppins text-sm">
                    Name: {patient.firstname}
                  </p>
                  <p className="font-poppins text-sm">Email: {patient.email}</p>
                  <p className="font-poppins text-sm">Phone: {patient.phone}</p>
                </div>
              </div>

              <p className="font-poppins text-lg">Appointment Details</p>

              <div>
                <p className="font-poppins text-sm">
                  Appointment Fees: {siteData?.appointmentfees}
                </p>
                <p className="font-poppins text-sm">Time: {values.time}</p>
                <p className="font-poppins text-sm">
                  Date: {formateDate(values.date)}
                  <p className="font-poppins text-sm">
                    Description: {values.description}
                  </p>
                </p>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="flex justify-center flex-col items-center ">
              <img
                src="https://cdn-icons-png.freepik.com/512/4436/4436481.png"
                alt="patient"
                className="w-28 h-28 rounded-full "
              />
              <p className="font-poppins  text-xl mt-5 font-bold">
                Appointment Booking Successfully
              </p>
              <p className="font-poppins text-sm ">
                You have recieved a confirmation email with the details address
                details and the appointment details.
              </p>
              <p className="font-poppins text-sm  mt-10">
                Thank you for booking Appointment.
              </p>

              <Link to="/" title="Go to Home" className="my-4" />
            </div>
          )}
        </div>

        <div className="flex justify-between items-center">
          {step !== 0 && step < 4 && (
            <Button
              title="Back"
              className="lg:w-2/12 py-2"
              onClick={() => setStep(step - 1)}
              loading={loading}
            />
          )}

          {step > 0 && step < 3 && (
            <Button
              title="Next"
              className="lg:w-2/12 py-2"
              onClick={() => {
                if (step === 2) {
                  handleSubmit();
                } else if (step === 1) {
                  setFieldValue("patientId", patient.patientId);
                  setStep(step + 1);
                }
              }}
              loading={loading}
            />
          )}

          {step === 3 && (
            <Button
              title="Pay"
              className="lg:w-2/12 py-2"
              onClick={doPayment}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BookAppointment;
