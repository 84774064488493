import AdminDashboard from "../pages/Admin/Dashboard";
import AdminRoute from "./AdminRoute";
import Layout from "../Layout/Layout";
import NotFoundPage from "../pages/NotFound";
import RecRoute from "./RecRoute";
import Appointments from "../pages/Admin/Appointments";
import Patient from "../pages/Admin/Patient/Patient";
import Revenue from "../pages/Admin/Revenue";
import Setting from "../pages/Admin/Setting";
import Feedback from "../pages/Admin/Feedback";
import Diseases from "../pages/Admin/Diseases";
import Patients from "../pages/Admin/Patients";
import Staff from "../pages/Admin/Staff";
import { Outlet } from "react-router-dom";
import AddStaff from "../Forms/Add/Staff";
import AddDisease from "../Forms/Add/Disease";
import Home from "../pages/Home";
import Transaction from "../pages/Admin/Transaction";
import Courasel from "../pages/Admin/Courasel";

export default function AuthRoute(isLoggedIn, role) {
  const routeObject = {
    role: role,
  };

  return [
    {
      path: "/",
      element: isLoggedIn ? <Layout /> : <Home />,
      children:
        role === "admin"
          ? [
              AdminRoute({
                ...routeObject,
                path: "/",
                element: <AdminDashboard />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/appointments",
                element: <Appointments />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/patients",
                element: <Outlet />,
                children: [
                  {
                    path: "/patients",
                    element: <Patients />,
                  },
                  {
                    path: "/patients/:id",
                    element: <Patient />,
                  },
                ],
              }),
              AdminRoute({
                ...routeObject,
                path: "/patient/:id",
                element: <Patient />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/courasel",
                element: <Courasel />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/transactions",
                element: <Transaction />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/staff/add",
                element: <AddStaff />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/diseases/add",
                element: <AddDisease />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/revenue",
                element: <Revenue />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/setting",
                element: <Setting />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/feedback",
                element: <Feedback />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/staff",
                element: <Staff />,
              }),
              AdminRoute({
                ...routeObject,
                path: "/diseases",
                element: <Diseases />,
              }),
            ]
          : role === "rec"
          ? [
              RecRoute({
                ...routeObject,
                path: "/appointments",
                element: <Appointments />,
              }),
              RecRoute({
                ...routeObject,
                path: "/dashboard",
                element: <AdminDashboard />,
              }),
              RecRoute({
                ...routeObject,
                path: "/patients",
                element: <Outlet />,
                children: [
                  {
                    path: "/patients",
                    element: <Patients />,
                  },
                ],
              }),
            ]
          : [
              {
                path: "/",
                element: <NotFoundPage />,
              },
            ],
    },
  ];
}
