import React from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import DropdownSelect from "../../components/core/Dropdown";
import { religions, educations, maritalStatus } from "../../Data/Options";
import Button from "../../components/core/Button";

function Personal({ loading, formik }) {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = formik;

  return (
    <Container>
      <TextInput
        label="Addiction"
        type="text"
        placeholder="Enter Addiction"
        value={values.addiction}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.addiction}
        errors={errors.addiction}
        name="addiction"
        id="addiction"
        maxLength={50}
        Icon={null}
      />

      <TextInput
        label="Allergies"
        type="text"
        placeholder="Enter Allergies"
        value={values.allergies}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.allergies}
        errors={errors.allergies}
        name="allergies"
        id="allergies"
        maxLength={50}
        Icon={null}
      />

      <TextInput
        label="Work Station"
        type="text"
        placeholder="Enter Work Station"
        value={values.workLocation}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.workLocation}
        errors={errors.workLocation}
        name="workLocation"
        id="workLocation"
        maxLength={50}
        Icon={null}
      />

      <DropdownSelect
        defaultValue="Select Religion"
        label="Religion"
        value={values.religion}
        options={religions}
        onChange={(e) => {
          setFieldValue("religion", e.target.value);
        }}
        onBlur={handleBlur}
        touched={touched.religion}
        errors={errors.religion}
        name="religion"
        id="religion"
      />
      <DropdownSelect
        defaultValue="Select education"
        label="Education"
        value={values.education}
        options={educations}
        onChange={(e) => {
          setFieldValue("education", e.target.value);
        }}
        onBlur={handleBlur}
        touched={touched.education}
        errors={errors.education}
        name="education"
        id="education"
      />

      <DropdownSelect
        defaultValue="Select Marital Status"
        value={values.maritalStatus}
        label="Marital Status"
        options={maritalStatus}
        onChange={(e) => {
          setFieldValue("maritalStatus", e.target.value);
        }}
        onBlur={handleBlur}
        touched={touched.maritalStatus}
        errors={errors.maritalStatus}
        name="maritalStatus"
        id="maritalStatus"
      />

      <TextInput
        label="Hear you Know"
        type="text"
        placeholder="Enter Hear you Know"
        value={values.hearYouKnow}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.hearYouKnow}
        errors={errors.hearYouKnow}
        name="hearYouKnow"
        id="hearYouKnow"
        maxLength={50}
        Icon={null}
      />

      <TextInput
        label="Referred By"
        type="text"
        placeholder="Enter Referred By"
        value={values.referredBy}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.referredBy}
        errors={errors.referredBy}
        name="referredBy"
        id="referredBy"
        maxLength={10}
        Icon={null}
      />

      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20 }}
        className="w-full "
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default Personal;
