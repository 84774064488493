import * as yup from "yup";

const appointmentSchema = yup.object().shape({
  date: yup.date().required("Date is required"),
  time: yup.string().required("Time is required"),
  patientId: yup.number().required("Patient is required"),
  description: yup.string().required("Description is required"),
});

export default appointmentSchema;
