import axios from "axios";
import { constant } from "./constant";

class Axios {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: constant.baseUrl,
    });

    this.setupInterceptors();
  }

  setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const userToken = localStorage.getItem("userToken");
        if (userToken) {
          config.headers["Authorization"] = `Bearer ${userToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Add any common response logic here
        return {
          data: response?.data?.data,
          success: response?.data?.success,
          message: this.getMessage(response?.data?.message),
        };
      },
      (error) => {
        return {
          data: error?.response?.data?.data,
          success: error?.response?.data?.success,
          message: this.getMessage(error?.response?.data?.message),
        };
      }
    );
  }

  async get(url, params) {
    return this.axiosInstance.get(url, { params });
  }

  async post(url, data) {
    return this.axiosInstance.post(url, data);
  }

  async put(url, data) {
    return this.axiosInstance.put(url, data);
  }

  async delete(url) {
    return this.axiosInstance.delete(url);
  }

  async patch(url, data) {
    return this.axiosInstance.patch(url, data);
  }

  getMessage(messages) {
    let message = "";
    if (typeof messages === "string") {
      message = messages;
    } else {
      let keys = Object.keys(messages.errors);
      message = messages.errors[keys[0]][0];
    }
    return message;
  }
}

export default Axios;
