import React, { useEffect, useState } from "react";
import Table from "../../../components/core/Table";
import { Box, TableRow } from "@mui/material";
import Sidebar from "../../../components/core/Sidebar";
import { useFormik } from "formik";
import { getApi, postApi } from "../../../hooks/Api";
import { toast } from "react-toastify";
import TabCell from "../../../components/core/TabCell";
import formateDate from "../../../hooks/formateDate";
import { VisibilityIcon } from "../../../Data/Icon";
import { useNavigate, useParams } from "react-router-dom";
import AddHistory from "../../../Forms/Add/History";
import Button from "../../../components/core/Button";
import patientDisease from "../../../validation/patientDisease";
import EyeAction from "../../../components/components/EyeAction";
import VideoComponent from "../../../components/components/ViewVideo";
import ViewImage from "../../../components/components/ViewImage";
import historySchema from "../../../validation/historySchema";
import { relations } from "../../../Data/Options";

function History() {
  const { id } = useParams();
  const [Toggle, setToggle] = useState(false);
  const [loading, setloading] = useState(false);
  const [patient, setpatient] = useState([]);
  const [data, setdata] = useState({});
  const [VideoToggle, setVideoToggle] = useState(false);
  const [ImageToggle, setImageToggle] = useState(false);

  const fetch = async () => {
    const response = await getApi("history");
    setpatient(response.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  const formik = useFormik({
    initialValues: {
      patientId: id, // Initialize with the default patient ID
      relationship: "", // Initialize with the default relationship
      since: "", // Initialize with the default since date
      description: "", // Initialize with the default description
      name: "",
    },
    validationSchema: historySchema,
    onSubmit: async (values) => {
      setloading(true);

      const response = await postApi("history", {
        ...values,
        relationship: relations.filter(
          (r) => parseInt(values.relationship) === r.id
        )[0]?.name,
      });
      if (response?.success) {
        toast.success(response?.message);
        setToggle(false);
      } else {
        toast.error(response?.message);
      }
      setloading(false);
    },
  });

  const tableHeaders = [
    { name: "Id", id: "Serial" },
    { name: "name", id: "name" },
    { name: "description", id: "Description" },
    { name: "since", id: "Since" },
    { name: "relation", id: "relation" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>{row?.name}</TabCell>
        <TabCell>{row?.description}</TabCell>
        <TabCell>{formateDate(row?.since)}</TabCell>
        <TabCell>{row?.relationship}</TabCell>
      </TableRow>
    );
  };
  return (
    <div className="">
      <div className="flex flex-row justify-between">
        <div></div>
        <Button
          title="Add History"
          className="bg-primary text-white  "
          onClick={() => {
            setToggle(true);
          }}
        />
      </div>

      <Table
        extra={{
          rows: patient ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
        }}
      />

      <VideoComponent
        toggle={VideoToggle}
        setToggle={setVideoToggle}
        url={data?.url}
      />
      <ViewImage
        toggle={ImageToggle}
        setToggle={setImageToggle}
        url={data?.url}
      />

      <Sidebar
        Toggle={Toggle}
        setToggle={setToggle}
        label="Add History"
        description="Here you can create new history."
      >
        <AddHistory formik={formik} loading={loading} />
      </Sidebar>
    </div>
  );
}

export default History;
