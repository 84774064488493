import React, { useEffect, useState } from "react";
import Container from "../../components/components/Container";
import Header from "../../components/components/Header";
import Table from "../../components/core/Table";
import { postApi } from "../../hooks/Api";
import TabCell from "../../components/core/TabCell";
import formateDate from "../../hooks/formateDate";
import { useNavigate } from "react-router-dom";
import EyeAction from "../../components/components/EyeAction";
import TableRow from "../../components/core/TableRow";

function Appointments() {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [currentpage, setcurrentPage] = useState(1);
  const [nextPage, setnextPage] = useState(null);
  const [previousPage, setpreviousPage] = useState(null);
  const [totalPages, settotalPages] = useState(0);

  const getData = async () => {
    const response = await postApi("appointment/get", {
      page: currentpage,
      size: 15,
    });
    setState(response?.data?.data);
    setnextPage(response?.data?.nextPage);
    setpreviousPage(response?.data?.previousPage);
    settotalPages(response?.data?.totalPages);
  };
  const handlePageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  useEffect(() => {
    getData();
  }, [currentpage]);

  const tableHeaders = [
    { name: "Id", id: "Serial" },
    { name: "Name", id: "Contact" },
    { name: "Appointment", id: "appointment" },
    { name: "Gender", id: "gender" },
    { name: "Mobile", id: "phone" },
    { name: "Registration Date", id: "registration" },
    { name: "Status", id: "status" },
    { name: "Action", id: "action" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;
    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{index + 1}</TabCell>
        <TabCell>
          {row?.patient?.gender === "Male" ? "Mr. " : "Mrs "}{" "}
          {row?.patient?.firstname}
        </TabCell>
        <TabCell>
          {formateDate(row?.date)} {row?.time.toLocaleString()}
        </TabCell>
        <TabCell>{row?.patient?.gender}</TabCell>
        <TabCell>{row?.patient?.phone}</TabCell>
        <TabCell>{formateDate(row?.createdAt)}</TabCell>
        <TabCell>{row?.status}</TabCell>
        <TabCell>
          <EyeAction
            onClick={() => {
              navigate(`/patients/${row?.patientId}`);
            }}
          />
        </TabCell>
      </TableRow>
    );
  };
  return (
    <Container>
      <Header
        title="Appointments"
        subtitle="You can see here Appointment and their details "
        btnTitle="Add Appointment"
        filterTitle="Filter Appointments"
        addClick={() => navigate("/book-appointment")}
      />

      <Table
        extra={{
          rows: state ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
          handlePageChange: handlePageChange,
          nextPage: nextPage,
          previousPage: previousPage,
          totalPages: totalPages,
          currentpage: currentpage,
        }}
      />
    </Container>
  );
}

export default Appointments;
