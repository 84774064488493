import React, { useEffect, useState } from "react";
import Table from "../../../components/core/Table";
import { Box, TableRow } from "@mui/material";
import Sidebar from "../../../components/core/Sidebar";
import { useFormik } from "formik";
import { getApi, postApi } from "../../../hooks/Api";
import { toast } from "react-toastify";
import TabCell from "../../../components/core/TabCell";
import formateDate from "../../../hooks/formateDate";
import { VisibilityIcon } from "../../../Data/Icon";
import { useNavigate, useParams } from "react-router-dom";
import AddPhysicalStats from "../../../Forms/Add/PhysicalStats";
import Button from "../../../components/core/Button";
import patientDisease from "../../../validation/patientDisease";
import EyeAction from "../../../components/components/EyeAction";
import VideoComponent from "../../../components/components/ViewVideo";
import ViewImage from "../../../components/components/ViewImage";
import historySchema from "../../../validation/historySchema";
import { relations } from "../../../Data/Options";
import physicalStats from "../../../validation/physicalStats";

function PhysicalStats() {
  const { id } = useParams();
  const [Toggle, setToggle] = useState(false);
  const [loading, setloading] = useState(false);
  const [patient, setpatient] = useState([]);
  const [data, setdata] = useState({});
  const [VideoToggle, setVideoToggle] = useState(false);
  const [ImageToggle, setImageToggle] = useState(false);

  const fetch = async () => {
    const response = await getApi("physical");
    setpatient(response.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  const formik = useFormik({
    initialValues: {
      weight: "",
      bmi: "",
      bodyFat: "",
      fat_freeWeight: "",
      subcutaneousFat: "",
      visceralFat: "",
      bodyWater: "",
      skeletalMuscle: "",
      muscleMass: "",
      boneMass: "",
      protein: "",
      bmr: "",
      metabolicAge: "",
    },
    validationSchema: physicalStats,
    onSubmit: async (values) => {
      setloading(true);
      const response = await postApi("physical", {
        ...values,
        relationship: relations.filter((r) => values.relationship === r.id)[0]
          ?.name,
      });
      if (response?.success) {
        toast.success(response?.message);
        setToggle(false);
      } else {
        toast.error(response?.message);
      }
      setloading(false);
    },
  });
  const tableHeaders = [
    { name: "Weight", id: "weight" },
    { name: "BMI", id: "bmi" },
    { name: "Body Fat", id: "bodyFat" },
    { name: "Fat-Free Weight", id: "fat_freeWeight" },
    { name: "Subcutaneous Fat", id: "subcutaneousFat" },
    { name: "Visceral Fat", id: "visceralFat" },
    { name: "Body Water", id: "bodyWater" },
    { name: "Skeletal Muscle", id: "skeletalMuscle" },
    { name: "Muscle Mass", id: "muscleMass" },
    { name: "Bone Mass", id: "boneMass" },
    { name: "Protein", id: "protein" },
    { name: "BMR", id: "bmr" },
    { name: "Metabolic Age", id: "metabolicAge" },
  ];

  const tableRoW = (props) => {
    const { row, index } = props;

    return (
      <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
        <TabCell>{row?.weight}</TabCell>
        <TabCell>{row?.bmi}</TabCell>
        <TabCell>{row?.bodyFat}</TabCell>
        <TabCell>{row?.fat_freeWeight}</TabCell>
        <TabCell>{row?.subcutaneousFat}</TabCell>
        <TabCell>{row?.visceralFat}</TabCell>
        <TabCell>{row?.bodyWater}</TabCell>
        <TabCell>{row?.skeletalMuscle}</TabCell>
        <TabCell>{row?.muscleMass}</TabCell>
        <TabCell>{row?.boneMass}</TabCell>
        <TabCell>{row?.protein}</TabCell>
        <TabCell>{row?.bmr}</TabCell>
        <TabCell>{row?.metabolicAge}</TabCell>
      </TableRow>
    );
  };
  return (
    <div className="">
      <div className="flex flex-row justify-between">
        <div></div>
        <Button
          title="Add Physical Stats"
          className="bg-primary text-white  "
          onClick={() => {
            setToggle(true);
          }}
        />
      </div>

      <Table
        extra={{
          rows: patient ?? [],
          columns: tableHeaders,
          RowComponent: tableRoW,
        }}
      />

      <VideoComponent
        toggle={VideoToggle}
        setToggle={setVideoToggle}
        url={data?.url}
      />
      <ViewImage
        toggle={ImageToggle}
        setToggle={setImageToggle}
        url={data?.url}
      />

      <Sidebar
        Toggle={Toggle}
        setToggle={setToggle}
        label="Add Physical Stats"
        description="Here you can create new history."
      >
        <AddPhysicalStats formik={formik} loading={loading} />
      </Sidebar>
    </div>
  );
}

export default PhysicalStats;
