import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "../core/Button";

function Header(props) {
  const { title, subtitle, btnTitle, addClick } = props;
  return (
    <Box className=" bg-white rounded-t-sm px-2 flex flex-row justify-between items-center">
      <Box>
        <Typography className="lg:text-lg text-sm font-semibold  tracking-wide">
          {title}
        </Typography>
        <Typography className=" text-xs text-gray-500  hidden md:block">
          {subtitle}
        </Typography>
      </Box>

      <Box className="flex flex-row space-x-4 ">
        {/* <Button variant="outlined" color="primary" startIcon={<FilterAltIcon />} title={filterTitle} onClick={filterClick} /> */}
        <Button
          variant="contained"
          color="primary"
          title={btnTitle}
          onClick={addClick}
        />
      </Box>
    </Box>
  );
}

export default Header;
