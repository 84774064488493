import React, { useEffect, useState } from "react";
import TextWithDescription from "../../../components/components/TextWithDescription";
import FormContainer from "../../../components/components/FormContainer";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import Sidebar from "../../../components/core/Sidebar";
import AddPersonal from "../../../Forms/Add/Peronsal";
import { educations, maritalStatus, religions } from "../../../Data/Options";
import { useParams } from "react-router-dom";
import { putApi } from "../../../hooks/Api";
import { ToastContainer, toast } from "react-toastify";

function Personal({ patient }) {
  const [Toggle, setToggle] = useState(false);
  const [loading, setloading] = useState(false);
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      workLocation: "",
      addiction: "",
      allergies: "",
      hearYouKnow: "",
      referredBy: "",
      education: "",
      religion: "",
      maritalStatus: "",
    },
    onSubmit: async (values) => {
      setloading(true);
      const response = await putApi(`patient/${id}`, {
        ...values,
        education: educations.filter(
          (item) => item.id === parseInt(values.education)
        )[0].name,
        religion: religions.filter(
          (item) => item.id === parseInt(values.religion)
        )[0].name,
        maritalStatus: maritalStatus.filter(
          (item) => item.id === parseInt(values.maritalStatus)
        )[0].name,
      });

      console.log(response);

      if (response?.success) {
        toast.success(response?.message);
        setToggle(false);
        formik.resetForm();
      } else {
        toast.error(response?.message);
      }
      setloading(false);
    },
  });

  const setFieldValue = () => {
    formik.setValues({
      workLocation: patient?.workLocation,
      addiction: patient?.addiction,
      allergies: patient?.allergies,
      hearYouKnow: patient?.hearYouKnow,
      referredBy: patient?.referredBy,
      education:
        educations.filter((item) => item?.value === patient?.education)[0]
          ?.id ?? "",
      religion:
        religions.filter((item) => item?.value === patient?.religion)[0]?.id ??
        "",
      maritalStatus:
        maritalStatus.filter(
          (item) => item?.value === patient?.maritalStatus
        )[0]?.id ?? "",
    });
  };

  useEffect(() => {
    setFieldValue();
  }, [patient]);

  return (
    <div className="px-4">
      <div className="flex flex-row justify-end">
        <EditIcon
          fontSize="small"
          className="cursor-pointer text-gray"
          onClick={() => {
            setToggle(true);
          }}
        />
      </div>
      <FormContainer>
        <TextWithDescription heading="Education" value={patient?.education} />
        <TextWithDescription
          heading="Work Location"
          value={patient?.workLocation}
        />

        <TextWithDescription heading="Addiction" value={patient?.addiction} />
        <TextWithDescription heading="Alergies" value={patient?.allergies} />

        <TextWithDescription heading="Religion" value={patient?.religion} />
        <TextWithDescription
          heading="Marital Status"
          value={patient?.religion}
        />

        <TextWithDescription heading="Reffred By" value={patient?.referredBy} />
        <TextWithDescription
          heading="Hear You Know"
          value={patient?.hearYouKnow}
        />
      </FormContainer>

      <Sidebar
        Toggle={Toggle}
        setToggle={setToggle}
        label="Personal Information"
        description="Here you can update  personal information"
      >
        <AddPersonal formik={formik} loading={loading} />
      </Sidebar>
    </div>
  );
}

export default Personal;
