import { createContext, useEffect, useState } from "react";
import Axios from "../utils/Axios";
import { getApi } from "../hooks/Api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(() => {
    const userToken = localStorage.getItem("userToken");
    return userToken ? true : false;
  });

  const [siteData, setsiteData] = useState({});

  const [role, setRole] = useState(() => {
    const userRole = localStorage.getItem("role");
    return userRole ? userRole : "public";
  });

  const login = async (data) => {
    const axios = new Axios();
    const response = await axios.post("user/login", data);

    if (response.success === true) {
      setAuthorized(true);
      localStorage.setItem("userToken", response.data.token);
      localStorage.setItem("role", response.data.role ? "admin" : "rec");
      setRole(response.data.role ? "admin" : "rec");
    }
    return response;
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    setAuthorized(false);
    localStorage.removeItem("role");
    setRole("public");
  };

  const init = async () => {
    const response = await getApi("/setting/1");
    const data = response?.settings;
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await init();

      setsiteData(data);
    };

    fetchData();
  }, []);

  return (
    <AuthContext.Provider
      value={{ login, logout, authorized, role, setRole, siteData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
