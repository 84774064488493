import React, { useState } from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import DropdownSelect from "../../components/core/Dropdown";
import { role } from "../../Data/Options";
import Button from "../../components/core/Button";
import ProfileDocument from "../../components/components/ProfileDocument";
import { useFormik } from "formik";
import { postApi } from "../../hooks/Api";
import staffSchema from "../../validation/staffSchema";
import { toast } from "react-toastify";
import FormContainer from "../../components/components/FormContainer";
import { useNavigate } from "react-router-dom";

function Staff() {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      role: "",
      profilePic: "",
      adhaarNumber: "",
    },
    validationSchema: staffSchema,
    onSubmit: async (values) => {
      setloading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("phone", values.phone);
      formData.append("address", values.address);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("pincode", values.pincode);
      formData.append(
        "role",
        role.filter((item) => item.id === parseInt(values.role))[0].name
      );
      formData.append("profilePic", values.profilePic);
      formData.append("adhaarNumber", values.adhaarNumber);

      const response = await postApi("user", formData);
      if (response?.success) {
        resetForm();
        toast.success(response?.message);
        navigate("/staff");
      } else {
        toast.error(response?.message);
      }
      setloading(false);
    },
  });

  return (
    <Container>
      <ProfileDocument
        touched={touched.profilePic}
        errors={errors.profilePic}
        label="Upload Image"
        Url={values.profilePic}
        setUrl={(e) => {
          setFieldValue("profilePic", e);
        }}
      />
      <FormContainer>
        <TextInput
          label="Name"
          type="text"
          placeholder="Enter Full Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          name="name"
          id="name"
          maxLength={50}
          Icon={null}
        />

        <TextInput
          label="Email"
          type="email"
          placeholder="Enter Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.email}
          errors={errors.email}
          name="email"
          id="email"
          maxLength={50}
          Icon={null}
        />

        <TextInput
          label="Password"
          type="password"
          placeholder="Enter Password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.password}
          errors={errors.password}
          name="password"
          id="password"
          maxLength={50}
          Icon={null}
        />

        <TextInput
          label="Phone"
          type="text"
          placeholder="Enter Phone"
          value={values.phone}
          onChange={handleChange}
          touched={touched.phone}
          errors={errors.phone}
          onBlur={handleBlur}
          name="phone"
          id="phone"
          maxLength={10}
          Icon={null}
        />

        <TextInput
          label="Adhaar Number"
          type="text"
          placeholder="Enter Adhaar Number"
          value={values.adhaarNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.adhaarNumber}
          errors={errors.adhaarNumber}
          name="adhaarNumber"
          id="adhaarNumber"
          maxLength={12}
          Icon={null}
        />
        <TextInput
          label="Address"
          type="text"
          placeholder="Enter Address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.address}
          errors={errors.address}
          name="address"
          id="address"
          maxLength={50}
          Icon={null}
        />

        <TextInput
          label="City"
          type="text"
          placeholder="Enter City"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.city}
          errors={errors.city}
          name="city"
          id="city"
          maxLength={50}
          Icon={null}
        />

        <TextInput
          label="State"
          type="text"
          placeholder="Enter State"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.state}
          errors={errors.state}
          name="state"
          id="state"
          maxLength={50}
          Icon={null}
        />

        <TextInput
          label="Pincode"
          type="text"
          placeholder="Enter Pincode"
          value={values.pincode}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.pincode}
          errors={errors.pincode}
          name="pincode"
          id="pincode"
          maxLength={6}
          Icon={null}
        />

        <DropdownSelect
          defaultValue="Select role"
          name="role"
          id="role"
          label="Role"
          value={values.role}
          options={role}
          touched={touched.role}
          errors={errors.role}
          onChange={(e) => {
            setFieldValue("role", e.target.value);
          }}
          onBlur={handleBlur}
        />
      </FormContainer>
      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20 }}
        className="w-full md:w-2/12"
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default Staff;
