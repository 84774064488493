import React from "react";
import Errortext from "./Errortext";

function TextInput({ className, ...props }) {
  return (
    <div className="my-1 w-full">
      <label className="mb-1 text-[13px]  block text-black font-poppins ">
        {props.label}
      </label>
      <input
        type={props.type}
        placeholder={props.placeholder}
        className={`${className} w-full rounded bg-white text-xs border-[1.5px] border-stroke bg-transparent font-poppins py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter `}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
        id={props.id}
        readOnly={props.readOnly}
      />
      {props.touched && props.errors ? (
        <Errortext error={props.errors} />
      ) : null}
    </div>
  );
}

export default TextInput;
