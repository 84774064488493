import React from "react";

function Offer() {
  const whyChooseDrAnoopChoudhary = [
    {
      id: 1,
      image:
        "https://media.licdn.com/dms/image/D5612AQG6aXapLs_szQ/article-cover_image-shrink_600_2000/0/1680528997125?e=2147483647&v=beta&t=gssBr5Mjpr0cK5-nwJMzVbWINTPjn0gWhWT3VGbg3SI",
      title: "Expertise in Homeopathy",
      description:
        "Dr. Anoop Choudhary holds a BHMS and an MD in Pediatrics, offering specialized care in homeopathy with years of experience in treating various health conditions.",
      number: "20+ years",
    },
    {
      id: 2,
      image:
        "https://static.vecteezy.com/system/resources/previews/009/731/669/non_2x/hand-and-love-icon-free-vector.jpg",
      title: "Personalized Treatment Plans",
      description:
        "Each patient receives a tailored treatment plan that addresses their unique health concerns, ensuring the most effective and holistic care.",
      number: "1000+ patients treated",
    },
    {
      id: 3,
      image:
        "https://img.freepik.com/free-vector/hand-drawn-little-asian-boy-cartoon-illustration_23-2150445628.jpg",
      title: "Pediatric Specialist",
      description:
        "As a certified pediatric homeopath, Dr. Choudhary provides expert care for children, addressing a wide range of pediatric health issues with gentle and effective homeopathic treatments.",
      number: "500+ children treated",
    },
    {
      id: 4,
      image:
        "https://t3.ftcdn.net/jpg/04/09/85/00/360_F_409850093_WZjLxcblZJv4ZO2yBmExfD8CRf4LLAji.jpg",
      title: "Trusted by Patients",
      description:
        "Patients have consistently praised Dr. Choudhary for his thorough consultations, compassionate approach, and successful treatment outcomes.",
      number: "4.8/5 rating",
    },
    {
      id: 5,
      image:
        "https://westleedervilleautos.com.au/wp-content/uploads/2019/10/Convenient-Location-300x300.png",
      title: "Convenient Location",
      description:
        "Located in Malviya Nagar, Bhopal, Dr. Choudhary's clinic is easily accessible, providing a comfortable and welcoming environment for all patients.",
      number: "Central location",
    },
    {
      id: 6,
      image: "https://cdn-icons-png.flaticon.com/512/375/375279.png",
      title: "Comprehensive Care",
      description:
        "From chronic conditions to acute ailments, Dr. Choudhary offers comprehensive care that covers a wide spectrum of health issues, ensuring holistic well-being.",
      number: "Broad spectrum of care",
    },
  ];

  return (
    <div className="lg:py-[5%] container  my-[5%] mx-auto px-6 " id="features">
      <p className="text-center text-black font-bold  font-poppins lg:text-4xl text-2xl ">
        Why Choose Dr Anoop Choudhary ?
      </p>
      <p className="text-center  font-normal  font-poppins text-base mt-5">
        Some of the reasons why patients choose Dr. Anoop Choudhary for their
        homeopathic care.
      </p>

      <div className="grid lg:grid-cols-3 gap-12 mt-10">
        {whyChooseDrAnoopChoudhary.map((data, index) => {
          return (
            <div
              key={index}
              className="w-full h-full bg-white items-start justify-start flex flex-col rounded-lg p-5 shadow-md hover:scale-105 transition "
            >
              <img
                src={data.image}
                alt={data.name}
                className="w-24 h-24 object-contain"
              />
              <p className="text-center  text-xl font-medium mt-4 text-black font-poppins">
                {data.title}
              </p>
              <p className="text-lg my-3 font-medium font-poppins text-gray">
                {data.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Offer;
