import React from "react";
import Container from "../../components/components/Container";
import Text from "../../components/core/Text";
import formateDate from "../../hooks/formateDate";
import { Box } from "@mui/material";

function Staff(props) {
  const { data } = props;

  return (
    <Container>
      <Box
        className="px-4 border border-gray-400 rounded-md py-5 w-11/12 mx-auto "
        id="my-node"
      >
        <img
          src={data?.profilePic}
          alt=""
          className="rounded-full w-32 h-32 mx-auto"
        />
        <Text className=" text-center text-base  leading-9   ">
          {data?.name} ({data?.admin ? "Admin" : "Assitant"})
        </Text>
        <Text>Email Address : {data?.email}</Text>
        <Text>Mobile Number : {data?.phone}</Text>
        <Text>Adhaar Number : {data?.adhaarNumber}</Text>
        <Text>Address : {data?.address}</Text>
        <Text>City : {data?.city}</Text>
        <Text>State : {data?.state}</Text>
        <Text>Pincode : {data?.pincode}</Text>
        <Text>Date of Joining : {formateDate(data?.dateOfJoining)}</Text>
      </Box>
    </Container>
  );
}

export default Staff;
