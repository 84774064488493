import React, { useEffect, useState } from "react";
import Container from "../../components/components/Container";
import { Card, Grid } from "@mui/material";
import { getApi, putApi } from "../../hooks/Api";
import Text from "../../components/core/Text";
import { StarRateIcon } from "../../Data/Icon";
import Button from "../../components/core/Button";
import { toast } from "react-toastify";
import Loading from "../../components/core/Loading";

function Feedback() {
  const [feedback, setfeedback] = useState([]);
  const [loading, setloading] = useState(false);

  const fetch = async () => {
    const response = await getApi("feedback/get");
    setfeedback(response.data);
  };

  useEffect(() => {
    fetch();
  }, []);

  const approvedFeedback = async (id, status) => {
    setloading(true);
    const response = await putApi(`feedback/${id}`, {
      approved: status,
    });
    setloading(false);

    if (response.success) {
      fetch();
      return toast.success(
        `FeedBack is ${status ? "Approved" : "Disapproved"} Now !`
      );
    }

    return toast.error(response.message);
  };

  return (
    <Container>
      <Loading isLoading={loading} />
      <Grid container spacing={2}>
        {feedback.length > 0 &&
          feedback.map((feedbacks, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                <Card className="p-3 ">
                  <img
                    src={feedbacks?.patient?.image}
                    alt=""
                    className="w-24 h-24 object-cover rounded-full m-"
                  />

                  <Text className="text-xs font-semibold mt-3">
                    {feedbacks?.patient?.name}
                  </Text>

                  {Array.from({ length: feedbacks.rating }, (_, index) => (
                    <StarRateIcon
                      key={index}
                      className="text-yellow-400"
                      fontSize="small"
                      style={{ padding: 0, margin: 0 }}
                    />
                  ))}

                  <Text className="text-xs mt-2">" {feedbacks?.comment} "</Text>

                  <Button
                    title={feedbacks?.approved ? "Disapprove" : "Approve"}
                    className="mt-5"
                    onClick={() => {
                      approvedFeedback(
                        feedbacks?.feedbackId,
                        !feedbacks?.approved
                      );
                    }}
                  />
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
}

export default Feedback;
