import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Text from "../components/core/Text";
import SidebarMenu from "../components/components/SidebarMenu";
import { useEffect } from "react";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, desktop }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: desktop && `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, desktop }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    desktop && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerLeft() {
  const [open, setOpen] = React.useState(false);
  const [desktop, setdesktop] = React.useState(false);
  const { siteData } = React.useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setdesktop(true);
        setOpen(true);
      } else {
        setdesktop(false);
      }
    };

    handleResize(); // Initial setup
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} desktop={desktop}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Text className="text-base">{siteData?.clinicName}</Text>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant={desktop ? "persistent" : "temporary"}
        anchor="left"
        open={open}
        onClose={() => setOpen(!open)}
      >
        <DrawerHeader className="space-x-4 px-4">
          <img
            src={siteData?.logo}
            alt=""
            className="w-10 h-10 bg-white rounded-full"
          />
          <Text className="text-lg text-gray font-bold">
            {siteData?.clinicName}
          </Text>
        </DrawerHeader>

        <SidebarMenu />
      </Drawer>
      <Main
        open={open}
        desktop={desktop}
        style={{
          width: "100%",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
