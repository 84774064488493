import React, { useContext, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import loginSchema from "../validation/loginSchema";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/core/TextInput";
import Loading from "../components/core/Loading";
import Button from "../components/core/Button";
import { EmailIcon, KeyIcon } from "../Data/Icon";
import loginImage from "../assets/images/login.png";

function Login() {
  const { login, siteData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        userType: "",
      },
      validationSchema: loginSchema,
      onSubmit: async (values) => {
        setloading(true);
        const response = await login({
          email: values.email,
          password: values.password,
        });

        if (response.success) {
          toast.success(response.message);
          navigate("/");
        } else {
          toast.error(response.message);
        }
        setloading(false);
      },
    });

  return (
    <Grid container component="main">
      <Loading isLoading={loading} />

      <Grid item sm={12} md={6} lg={9} xs={12}>
        <img
          src={loginImage}
          alt="doctors"
          className="w-full h-screen object-contain bg-slate-200 lg:block hidden"
        />
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        lg={3}
        xs={12}
        component={Box}
        className="h-screen flex justify-around items-center flex-col "
      >
        <Box className="self-center px-8 w-full space-y-4">
          <img
            src={siteData?.logo}
            alt=""
            className="m-auto w-32 h-32 object-contain"
          />
          <Typography className="mb-10 text-center text-2xl font-bold text-slate-600  ">
            Sign in to your account
          </Typography>
          <TextInput
            placeholder="Enter your Email"
            name="email"
            id="email"
            touched={touched.email}
            errors={errors.email}
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            Icon={<EmailIcon />}
          />

          <TextInput
            placeholder="Enter your Password"
            type="password"
            name="password"
            id="password"
            touched={touched.password}
            errors={errors.password}
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            Icon={<KeyIcon />}
          />

          <Button
            title="Sign in"
            onClick={handleSubmit}
            loading={loading}
            variant="contained"
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
