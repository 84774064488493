import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";

function Faq() {
  const faq = [
    {
      question: "What is homeopathy, and how does it work?",
      answer:
        "Homeopathy is a holistic system of medicine based on the principles of 'like cures like' and individualized treatment. It involves using highly diluted substances to stimulate the body's natural healing processes, aiming to address the root cause of symptoms by considering the person as a whole.",
    },
    {
      question: "What conditions can homeopathy doctors in Bhopal treat?",
      answer:
        "Homeopathy doctors in Bhopal can treat a wide range of conditions, including chronic and acute ailments. Commonly addressed issues include allergies, respiratory disorders, skin conditions, digestive problems, stress-related health concerns, and women's health issues.",
    },
    {
      question: "How do homeopathy doctors conduct consultations in Bhopal?",
      answer:
        "Homeopathy consultations in Bhopal typically involve a comprehensive assessment of the patient's physical, mental, and emotional aspects. Doctors take time to understand the individual's medical history, symptoms, and lifestyle to formulate a personalized treatment plan.",
    },
    {
      question: "Is homeopathy safe for children and the elderly?",
      answer:
        "Yes, homeopathy is considered safe for individuals of all ages, including children and the elderly. Homeopathy doctors in Bhopal often specialize in pediatric and geriatric care, providing gentle and non-invasive treatments that are well-tolerated.",
    },
    {
      question:
        "How long does it take to see results with homeopathic treatment?",
      answer:
        "The time it takes to see results with homeopathic treatment varies depending on the individual and the nature of the health condition. Some people may experience improvement relatively quickly, while others may require more time for a gradual and sustained healing process.",
    },
    {
      question: "Can homeopathy be used alongside conventional medicine?",
      answer:
        "Yes, homeopathy can often be used alongside conventional medicine. It's important to inform both your homeopathy doctor and conventional healthcare provider about all treatments you are undergoing to ensure coordinated and safe care.",
    },
    {
      question:
        "What qualifications and experience does Dr. Anoop Choudhary have?",
      answer:
        "Dr. Anoop Choudhary holds a BHMS from Hahnemann Homeopathic Medical College and an MD in Pediatrics from Maharashtra University of Health Sciences. He specializes in pediatric care and has extensive experience in treating various health conditions with homeopathy.",
    },
    {
      question: "Where is Dr. Anoop Choudhary's clinic located?",
      answer:
        "Dr. Anoop Choudhary's clinic, Homeo Hopes, is located at G-4, Jeevan Sagar Apartment, 6, Malviya Nagar, Bhopal, Madhya Pradesh - 463002.",
    },
    {
      question: "How can I schedule an appointment with Dr. Anoop Choudhary?",
      answer:
        "You can schedule an appointment with Dr. Anoop Choudhary by calling his clinic at the provided contact number. Alternatively, you can visit his profile on Practo or Chikitsa for more details and online appointment options.",
    },
  ];

  return (
    <div className=" py-[6%] lg:py-[5%] container mx-auto px-6" id="faq">
      <p className="text-center text-black font-bold  font-poppins lg:text-4xl text-2xl">
        Frequently Asked Questions
      </p>
      <p className="text-center  font-normal  font-poppins text-base mt-5">
        Some of the Question and answer asking about the app
      </p>

      <div className=" mt-[5%]">
        {faq?.map((data, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                className="font-poppins text-base"
                expandIcon={<AddIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {data.question}
              </AccordionSummary>
              <AccordionDetails className="font-poppins text-sm">
                {data.answer}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default Faq;
