import { Box, Container } from "@mui/material";
import React from "react";

function TabPanel({ ...props }) {
  const { children, value, index, ...other } = props;
  return (
    <Box {...other}>
      {value === index && <Box className="my-5">{children}</Box>}
    </Box>
  );
}

export default TabPanel;
