import React from "react";
import Container from "../../components/components/Container";
import TextInput from "../../components/core/TextInput";
import Button from "../../components/core/Button";
import UploadDocument from "../../components/core/UploadDocument";
import Dpicker from "../../components/core/Dpicker";
import { diseases, relations } from "../../Data/Options";
import DropdownSelect from "../../components/core/Dropdown";
import Textarea from "../../components/core/Textarea";

function History(props) {
  const { loading, formik } = props;
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
  } = formik;

  return (
    <Container>
      <TextInput
        label="Name"
        placeholder="Enter name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.name}
        errors={errors.name}
        name="name"
        id="name"
        Icon={null}
        multiline={true}
      />
      <Textarea
        label="Description"
        placeholder="Enter Description"
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        touched={touched.description}
        errors={errors.description}
        name="description"
        id="description"
        Icon={null}
        multiline={true}
      />

      <DropdownSelect
        defaultValue="Select Relation "
        value={values.relationship}
        label="Relation "
        options={relations}
        onChange={(e) => {
          setFieldValue("relationship", e.target.value);
        }}
        onBlur={handleBlur}
        touched={touched.relationship}
        errors={errors.relationship}
        name="relationship"
        id="relationship"
      />

      <Dpicker
        label="When"
        placeholder="Since"
        value={values.since}
        onChange={(e) => {
          setFieldValue("since", e);
        }}
        onBlur={handleBlur}
        touched={touched.since}
        errors={errors.since}
        name="since"
        id="since"
        maxLength={10}
        Icon={null}
      />

      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20, width: "100%" }}
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default History;
