import React from "react";
import Button from "../components/core/Button";
import { useNavigate } from "react-router-dom";

function Success() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center flex-col items-center min-h-screen">
      <img
        src="https://cdn-icons-png.freepik.com/512/4436/4436481.png"
        alt="patient"
        className="w-48 h-48 rounded-full "
      />
      <p className="font-poppins  text-2xl mt-5 font-bold my-5">
        Patient Registration Successfully
      </p>
      <p className="font-poppins text-base text-center lg:w-4/12 w-10/12">
        You have received an email on your email Id and Whatsapp Number
        containing the registration Details, including the registration number.
      </p>
      <p className="font-poppins text-base font-bold  mt-10">
        Thank you for the Registration.
      </p>

      <Button
        onClick={() => {
          navigate("/book-appointment");
        }}
        title="Book Appointment Now"
        className="my-10 bg-primary text-white py-4"
      />
    </div>
  );
}

export default Success;
