import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Box, Card } from "@mui/material";
import { deleteApi, postApi } from "../../hooks/Api";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddImages from "../../Forms/Add/AddImages";
import Sidebar from "../../components/core/Sidebar";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { toast } from "react-toastify";

function Courasel() {
  const [Images, setImages] = useState([]);
  const [Toggle, setToggle] = useState(false);

  const getData = async () => {
    const response = await postApi("courasel/get");
    setImages(response?.data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteImage = async (id) => {
    const response = await deleteApi(`courasel/${id}`);

    if (response.success) {
      toast.success(response.message);
      getData();
    }
  };

  return (
    <div className="relative">
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        stopOnHover={true}
        showStatus={false}
        swipeable={true}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <Box
              sx={{
                position: Images.length > 0 ? "absolute" : "hidden",
                top: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: 0,
                left: 0,
                padding: 3,
                zIndex: 10,
              }}
              onClick={clickHandler}
            >
              <ArrowLeftIcon color="info" fontSize="medium" />
            </Box>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <Box
              sx={{
                position: Images.length > 0 ? "absolute" : "hidden",
                top: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: 0,
                right: 0,
                padding: 3,
                zIndex: 10,
              }}
              onClick={clickHandler}
            >
              <ArrowRightIcon color="info" fontSize="medium" />
            </Box>
          );
        }}
      >
        {Images &&
          Images.map((item, index) => (
            <div className="md:h-[70vh] h-72 rounded-sm" key={index}>
              <img
                src={item.image}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: 5,
                }}
              />
            </div>
          ))}
      </Carousel>

      <div className="flex flex-row flex-wrap mt-8 space-x-3  relative">
        {Images?.length > 0 &&
          Images?.map((item, index) => {
            return (
              <div className="relative" key={index}>
                <DeleteOutlineOutlinedIcon
                  onClick={() => {
                    deleteImage(item?.couraselId);
                  }}
                  className="bg-red-500 text-white hover:bg-red-700 absolute top-0 right-0 rounded-sm"
                />
                <img
                  src={item.image}
                  alt=""
                  style={{
                    height: 100,
                    width: 200,
                    objectFit: "cover",
                    borderRadius: 5,
                  }}
                />
              </div>
            );
          })}
      </div>

      <Sidebar
        Toggle={Toggle}
        setToggle={setToggle}
        label="Add Banner Images"
        description="Add Banner Images here for the different Offers"
      >
        <AddImages setToggle={setToggle} />
      </Sidebar>

      <Card
        elevation={5}
        sx={{
          position: "absolute",
          top: 10,
          right: 50,
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            fontSize: 12,
            borderRadius: 20,
            width: 50,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
          }}
          onClick={() => setToggle(true)}
        >
          <CloudUploadIcon color="primary" />
        </Box>
      </Card>
    </div>
  );
}

export default Courasel;
