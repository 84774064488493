import React, { useState } from "react";
import Container from "../../components/components/Container";
import Button from "../../components/core/Button";
import Textarea from "../../components/core/Textarea";
import FormContainer from "../../components/components/FormContainer";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import diseaseSchema from "../../validation/diseaseSchema";
import { postApi } from "../../hooks/Api";

function Disease() {
  const [loading, setloading] = useState(false);
  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      initialValues: {
        name: "",
        description: "",
        symptoms: "",
        causes: "",
      },
      validationSchema: diseaseSchema,
      onSubmit: async (values) => {
        setloading(true);
        const response = await postApi("disease", values);
        if (response?.success) {
          toast.success(response?.message);
        } else {
          toast.error(response.message);
        }
        setloading(false);
      },
    });

  return (
    <Container>
      <FormContainer>
        <Textarea
          label="Name"
          type="text"
          placeholder="Enter Disease Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          name="name"
          id="name"
          maxLength={50}
          Icon={null}
        />
        <Textarea
          label="Description"
          placeholder="Enter Description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.description}
          errors={errors.description}
          name="description"
          id="description"
          Icon={null}
          multiline={true}
        />

        <Textarea
          label="Symptoms"
          placeholder="Enter Symptoms"
          value={values.symptoms}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.symptoms}
          errors={errors.symptoms}
          name="symptoms"
          id="symptoms"
          Icon={null}
          multiline={true}
        />

        <Textarea
          label="Causes"
          placeholder="Enter Causes"
          value={values.causes}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.causes}
          errors={errors.causes}
          name="causes"
          id="causes"
          Icon={null}
          multiline={true}
        />
      </FormContainer>
      <Button
        variant="contained"
        title="Submit"
        style={{ marginBlock: 20 }}
        className="w-full md:w-2/12"
        onClick={handleSubmit}
        loading={loading}
      />
    </Container>
  );
}

export default Disease;
