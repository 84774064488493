import React from "react";
import robot from "../../assets/images/dr_anoop.jpg";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function About() {
  return (
    <div
      className="container mx-auto  my-[8%]  items-center grid grid-cols-1 lg:grid-cols-2 gap-8 px-6"
      id="about"
    >
      <img
        src={robot}
        alt=""
        className="w-72 h-72 md:w-96 md:h-96 lg:w-100 lg:h-100 xl:h-115 xl:w-115 object-cover  rounded-sm   mx-auto  "
      />

      <div className="">
        <p className="text-secondary font-poppins font-semibold">
          About Dr Anoop Choudhary
        </p>
        <h2 className="text-2xl lg:text-4xl font-bold my-4 font-poppins text-center lg:text-start ">
          All-in-One Health Solution
        </h2>
        <p className="text-primary font-poppins font-semibold my-5">
          25 Years Of Experience in Medical Services
        </p>

        <p className="text-gray font-sm font-poppins">
          Dr. Anoop Choudhary is a distinguished homeopathy practitioner based
          in Bhopal, known for his expertise in holistic healthcare. He holds a
          BHMS degree from Hahnemann Homeopathic Medical College and an MD in
          Pediatrics from Maharashtra University of Health Sciences. With over
          20 years of experience, Dr. Choudhary has successfully treated
          thousands of patients, addressing a wide range of health issues from
          chronic conditions to acute ailments. Dr. Choudhary’s practice, Homeo
          Hopes, is located in the heart of Malviya Nagar, Bhopal, making it
          easily accessible for patients seeking reliable homeopathic care. His
          clinic is renowned for its personalized treatment plans, where each
          patient receives tailored care based on their unique medical history
          and health concerns.
        </p>

        <div className="flex flex-row flex-wrap justify-start items-center my-10 gap-4">
          <Link
            target="_blank"
            className=" bg-secondary px-8 py-3 rounded-full flex flex-row items-center justify-center space-x-2 hover:bg-primary  ease-out hover:scale-105 transition"
          >
            <p className="text-sm font-semibold text-white font-poppins ">
              Know More +
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default About;
