import { TableCell } from '@mui/material'
import React from 'react'

function TabCell({ children }) {
    return (
        <TableCell align='center' className='text-[13px]'>
            {children}
        </TableCell>
    )
}

export default TabCell